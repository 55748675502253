import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import { AssetService } from "@services";
import store from "@/store";
import { isEqual, transform, isObject, isArray } from "lodash";
export default {
    computed: {
        ...mapGetters("settings", ["settings"]),

        timeFormat24Hour: {
            get() {
                if (this.settings && this.settings.timeFormat24Hour) {
                    return this.settings.timeFormat24Hour;
                }
            },
        },
    },
    methods: {
        async getImageFromS3(assetId, useThumbnail = false) {
            try {
                const r = await AssetService.downloadAssetUrl(assetId);
                if (r?.data == null) return;

                const url = useThumbnail && r.data.thumbnailUrl ? r.data.thumbnailUrl : r.data.downloadUrl;

                if (!url) return;

                const d = await fetch(url);
                const imageBlob = await d.blob();
                const imageUrl = URL.createObjectURL(imageBlob);
                return imageUrl;
            } catch (err) {
                //silent error
            }
        },

        async getFileFromS3(assetId) {
            try {
                const r = await AssetService.downloadAssetUrl(assetId);
                if (r?.data?.downloadUrl == null) return;
                return await fetch(r.data.downloadUrl);
            } catch (err) {
                //silent error
            }
        },

        /**
         * Processes an image from a base64 string, resizing it to the specified dimensions and quality.
         *
         * @param {string} base64String - The base64 encoded string of the image to be processed.
         * @param {Object} options - Optional parameters for processing the image.
         * @param {number} [options.maxDimension=1024] - The maximum dimension (width or height) for the processed image.
         * @param {number} [options.quality=0.9] - The quality of the processed image (0 to 1).
         * @returns {Promise<string>} A promise that resolves to the processed base64 string of the image.
         * @example
         * const processedImage = await this.processImage(originalBase64String, { maxDimension: 800, quality: 0.85 });
         */
        async processImage(base64String, { maxDimension = 600, quality = 0.7 } = {}) {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    // Calculate dimensions maintaining aspect ratio
                    let width = img.width;
                    let height = img.height;

                    // Use provided maxDimension (default 1024px)
                    const targetMaxWidth = maxDimension;

                    // Scale to max width/height while maintaining aspect ratio
                    if (width > height) {
                        if (width > targetMaxWidth) {
                            height = Math.round((height * targetMaxWidth) / width);
                            width = targetMaxWidth;
                        }
                    } else {
                        if (height > targetMaxWidth) {
                            width = Math.round((width * targetMaxWidth) / height);
                            height = targetMaxWidth;
                        }
                    }

                    // Set canvas dimensions and draw
                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);

                    // Convert to base64 with appropriate quality
                    const processedBase64 = canvas.toDataURL("image/jpeg", quality).split(",")[1];
                    resolve(processedBase64);
                };
                img.src = `data:image/jpeg;base64,${base64String}`;
            });
        },

        snackbar(messageKey, color, timeout) {
            this.$snackbar.showMessage({
                content: this.$t(messageKey),
                color,
                timeout,
            });
        },

        //ie Thu Aug 12 2021
        getLocalLongIsoDate(isoDate) {
            const options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            };
            return new Date(isoDate).toDateString("default", options);
        },

        getLocalIsoTime(isoDate) {
            let hour12 = this.settings && this.settings.timeFormat24Hour ? false : true;
            var options = { hour12: hour12 };
            return new Date(isoDate).toLocaleTimeString("en-US", options);
        },

        getTime24(value) {
            return DateTime.fromISO(value, { zone: "utc" }).toFormat("HH:mm:ss");
        },

        getDuration(start, end, addSeconds = false) {
            if (start && end) {
                let diff;
                let date1 = DateTime.fromISO(end);
                let date2 = DateTime.fromISO(start);
                let diffCheck = date1.diff(date2).toMillis();
                if (diffCheck < 0) {
                    return "0:00";
                }
                if (addSeconds) {
                    diff = date1.diff(date2).toFormat("hh:mm:ss");
                } else {
                    diff = date1.diff(date2).toFormat("hh:mm");
                }
                return diff.replace(/^0+/, "");
            }
            return "0:00";
        },

        getObjectDifference(obj1, obj2) {
            function changes(obj2, obj1) {
                let arrayIndexCounter = 0;
                return transform(obj2, function (result, value, key) {
                    if (!isEqual(value, obj1[key])) {
                        let resultKey = isArray(obj1) ? arrayIndexCounter++ : key;
                        result[resultKey] = isObject(value) && isObject(obj1[key]) ? changes(value, obj1[key]) : value;
                    }
                });
            }
            return changes(obj2, obj1);
        },

        formatDateTimeContextual(value) {
            //Time since context: 9:38 AM, Tue 9:38 AM, Tue 11/16, 11/16/2021
            // TODO: turn into filter + use in dashboard for duration?
            if (!value) {
                return;
            }
            if (DateTime.fromISO(value) > DateTime.now().startOf("day")) {
                if (this.timeFormat24Hour) {
                    return DateTime.fromISO(value).toFormat("T");
                } else {
                    return DateTime.fromISO(value).toFormat("t");
                }
            } else if (DateTime.fromISO(value) > DateTime.now().minus({ weeks: 1 }).startOf("week")) {
                if (this.timeFormat24Hour) {
                    return DateTime.fromISO(value).toFormat("ccc T");
                } else {
                    return DateTime.fromISO(value).toFormat("ccc t");
                }
            } else if (DateTime.fromISO(value) > DateTime.now().minus({ days: 30 }).startOf("day")) {
                return DateTime.fromISO(value).toFormat("ccc M/d");
            } else {
                return DateTime.fromISO(value).toFormat("M/d/yyyy");
            }
        },

        filterKeyDigits(e) {
            if (
                /[0-9]/.test(e.key) ||
                e.ctrlKey ||
                e.metaKey ||
                e.key === "Backspace" ||
                e.key === "Tab" ||
                e.key === "Delete" ||
                e.key === "Home" ||
                e.key === "End" ||
                e.key === "ArrowLeft" ||
                e.key === "ArrowRight"
            ) {
                return;
            }
            e.preventDefault();
        },

        diff(obj1, obj2) {
            return Object.keys(obj1).reduce((obj, key) => {
                if (Object.prototype.hasOwnProperty.call(obj2, key)) {
                    const v1 = obj1[key];
                    const v2 = obj2[key];
                    const isDate = DateTime.fromISO(v1).isValid;
                    if (isDate) {
                        if (!DateTime.fromISO(v1, { zone: "utc" }).equals(DateTime.fromISO(v2, { zone: "utc" }))) {
                            obj[key] = v1;
                        }
                    } else if (v1 !== v2) {
                        obj[key] = v1;
                    }
                } else {
                    obj[key] = obj1[key];
                }
                return obj;
            }, {});
        },

        isEqualDates(date1, date2) {
            if (!date1 && !date2) return true;
            const d1 = DateTime.fromISO(date1, { zone: "utc" });
            const d2 = DateTime.fromISO(date2, { zone: "utc" });
            return d1.equals(d2);
        },

        geoLatLngFromISO6709format(coords) {
            return coords ? coords.slice(0, -1).replace("-", ",-").replace("+", ",+").substring(1) : "";
        },

        locationMethodParser(locationMethod) {
            const unknownLocationMethod = {
                method: "unknown",
                description: "Unknown Location Method",
                icon: "mdi-map-marker-question-outline",
            };

            const locationMethods = [
                { method: "web", description: "Web Browser", icon: "mdi-monitor" },
                { method: "desktop_web", description: "Desktop Web Browser", icon: "mdi-monitor" },
                { method: "mobile_web", description: "Mobile Web Browser", icon: "mdi-cellphone" },
                { method: "android_app", description: "Android App", icon: "mdi-cellphone" },
                { method: "ios_app", description: "IOS App", icon: "mdi-cellphone" },
                { method: "manual_entry", description: "Manually Set Location", icon: "mdi-crosshairs-gps" },
            ];

            let locationResult = locationMethods.find((x) => x.method == locationMethod);

            if (!locationResult) {
                locationResult = unknownLocationMethod;
            }

            return locationResult;
        },

        portalEnv() {
            const url = store.state.api.userAppUrl;
            if (url.includes("dev") || url.includes("portalpr")) {
                return "dev";
            } else if (url.includes("stage")) {
                return "stage";
            } else {
                return "prod";
            }
        },

        async copyToClipboard(str) {
            try {
                await navigator.clipboard.writeText(str);
                return true;
            } catch (e) {
                return false;
            }
        },
    },
};
