<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { TokenService } from "@services";
import { tracer } from "@/diagnostics/tracer.js";
import helpers from "@mixins/helpers";

tracer.init();

export default {
    name: "App",

    mixins: [helpers],

    computed: {
        ...mapGetters("settings", ["settings"]),
        ...mapGetters("ui", ["showPlanners"]),
        ...mapGetters("user", ["user"]),

        layout() {
            return this.$route.meta.layout || "default-layout";
        },
    },

    metaInfo: {
        //TODO: title: this.$router.currentRoute,
        //TODO: explore useful meta info
        titleTemplate: "%s | Ranes",
    },

    methods: {
        ...mapActions("user", ["setUser", "setUserCreds"]),
        ...mapActions("heartbeat", ["checkHeartbeat"]),
        ...mapActions("ui", ["setShowPlanners"]),

        async init() {
            if (TokenService.getToken()) {
                await this.setUser();
                await this.setUserCreds();
                await this.checkHeartbeat();
                await this.checkNavVisibility();
            }
        },

        async checkNavVisibility() {
            try {
                if (
                    this.user.navigationAccess?.planners === undefined ||
                    this.user.navigationAccess?.planners === null
                ) {
                    const ENV_MAPPING = {
                        dev: "dev",
                        stage: "stage",
                        prod: "prod",
                    };

                    const env = ENV_MAPPING[this.portalEnv()] || "prod";

                    const response = await fetch(
                        `https://clockworksafety-public.s3.us-west-2.amazonaws.com/${env}/companyplanners.json`,
                    );

                    const data = await response.json();
                    const userCompanyId = this.user?.companyId;

                    if (!userCompanyId) {
                        console.warn("User company ID not found");
                        return;
                    }

                    const company = data.companies.find((c) => c.id == userCompanyId);
                    console.log("Company data:", { userCompanyId, company });

                    if (company) {
                        this.setShowPlanners(company.planner);
                    }
                } else {
                    this.setShowPlanners(this.user.navigationAccess.planners);
                }
            } catch (error) {
                console.error("Error checking nav visibility:", error);
                return;
            }
        },
    },

    watch: {
        user: {
            immediate: true,
            async handler(newUser) {
                if (newUser && Object.keys(newUser).length > 0) {
                    await this.checkNavVisibility();
                }
            },
        },
    },

    mounted() {
        if (this.layout == "share-layout") {
            this.$vuetify.theme.dark = false;
        } else {
            if (this.settings.darkTheme != null) {
                this.$vuetify.theme.dark = this.settings.darkTheme;
            } else {
                this.$vuetify.theme.dark = true;
            }
        }

        this.init();
    },
};
</script>
