const state = {
    showPlanners: false,
};

const getters = {
    showPlanners: (state) => {
        return state.showPlanners;
    },
};

const mutations = {
    setShowPlanners: (state, value) => {
        state.showPlanners = value;
    },
};

const actions = {
    setShowPlanners: ({ commit }, value) => {
        commit("setShowPlanners", value);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
