import { get, post, patch, del } from "./api.service";

const PlannerService = {
    getPlanner(id) {
        return get("/admin/planner", { id });
    },

    addPlanner(params) {
        return post("/admin/planner", params);
    },

    patchPlanner(params) {
        return patch("/admin/planner", params);
    },

    deletePlanner(id) {
        return del(`/admin/planner/${id}`);
    },

    searchPlanner(params) {
        return get("/admin/planner/search", params);
    },

    patchAssignment(params) {
        return patch("/admin/planner/assignment", params);
    },

    createAssignments(params) {
        return post("/admin/planner/assignment", params);
    },

    deleteAssignment(id) {
        return del(`/admin/planner/assignment/${id}`);
    },

    getAssignment(id) {
        return get(`/admin/planner/assignment/${id}`);
    },

    searchAssignments(params) {
        return get("/admin/planner/assignment/search", params);
    },

    getSubmission(id) {
        return get("/admin/planner/submission", { id });
    },

    addSubmission(params) {
        return post("/admin/planner/submission", params);
    },

    updateSubmission(params) {
        return patch("/admin/planner/submission", params);
    },

    searchSubmissions(params) {
        return get("/admin/planner/submission/search", params);
    },

    proxyRequest(params) {
        return post("/admin/util/proxy", params);
    },
};

export { PlannerService };
